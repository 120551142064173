<template>
    <div class="page-modal">
        <!-- 弹出框 -->
        <el-dialog v-model="showModal" width="80%" center>
            <el-descriptions :column="2" border>
                <el-descriptions-item label="商户号">{{ currentOrder.merchant_no }}</el-descriptions-item>
                <el-descriptions-item label="商户名称">{{ currentOrder.merchant_name }}</el-descriptions-item>
                <el-descriptions-item label="门店名称">{{ currentOrder.store_name }}</el-descriptions-item>
                <el-descriptions-item label="创建时间">
                    {{ dayjs(currentOrder.created_at).format("YYYY-MM-DD H:mm:ss") }}
                </el-descriptions-item>
                <el-descriptions-item label="设备ID(终端)">{{ currentOrder.device_id }}</el-descriptions-item>
                <el-descriptions-item label="代理商名称">{{ currentOrder.agent_name }}</el-descriptions-item>
                <el-descriptions-item label="退款金额">
                    <text style="color: brown;">{{ currentOrder.refund_amount }}</text>
                </el-descriptions-item>
                <el-descriptions-item label="退款状态">
                    <span v-if="currentOrder.status_format == 'Success'">
                        <el-tag type="success">退款成功</el-tag>
                    </span>
                    <span v-else>
                        <el-tag type="danger">退款失败</el-tag>
                    </span>
                </el-descriptions-item>
                <el-descriptions-item label="平台订单号">{{ currentOrder.trade_no }}</el-descriptions-item>
                <el-descriptions-item label="平台退款单号">{{ currentOrder.refund_no }}</el-descriptions-item>
                <el-descriptions-item label="商户退款单号">{{ currentOrder.out_refund_no }}</el-descriptions-item>

                <el-descriptions-item label="支付方式">{{ currentOrder.payment_method }}</el-descriptions-item>
                <el-descriptions-item label="支付渠道">
                    {{ getChannelLabel(currentOrder.payment_channel_id) }}
                </el-descriptions-item>
                <el-descriptions-item label="支付渠道编码">{{ currentOrder.payment_channel_code }}</el-descriptions-item>
                <el-descriptions-item label="备注">{{ currentOrder.remarks }}</el-descriptions-item>
            </el-descriptions>

            <template #footer>
                <span class="dialog-footer">
                    <el-button @click="showModal = false">关闭</el-button>
                </span>
            </template>
        </el-dialog>
    </div>
</template>

<script setup>
import { ref, defineExpose, defineProps, computed } from 'vue';
import dayjs from "dayjs"

// 接收父组件传递的 orderList
const props = defineProps(['channelList']);


const showModal = ref(false);

// 当前选中的订单数据
const currentOrder = ref(null);

// 打开弹出框并设置当前订单数据
const openModal = (order) => {
    showModal.value = true;
    currentOrder.value = order
};

// 使用 map 方法将 channelList 转换为一个映射对象
const channelMap = computed(() => {
    const map = {};
    props.channelList.map(channel => {
        map[channel.value] = channel.label;
    });
    return map;
});

// 根据 payment_channel_id 获取对应的 label
const getChannelLabel = (paymentChannelId) => {
    return channelMap.value[paymentChannelId] || '未知渠道'; // 如果找不到对应的 label，返回默认值
};



// 暴露 openModal 方法，供父组件调用
defineExpose({ openModal });
</script>

<style lang="less" scoped>
.page-modal {
    text-align: center;
    padding: 20px;

    .statuscheck {
        display: flex;
        justify-content: center;
        align-items: center;
        margin-bottom: 20px;
    }
}

.dialog-footer {
    text-align: center;
}
</style>